<template>
  <v-container>
    <v-form :readonly="!canEditSettings">
      <v-card>
        <v-card-title>Invoice</v-card-title>
        <v-card-text>
          <h3 class="mb-3">Vehicle/Driver Assignment Form Sections</h3>
          <v-row dense>
            <v-col cols="12" md="12">
              <v-subheader class="pl-0">
                Display district name and address at the top of the printed invoice?
              </v-subheader>
              <v-radio-group v-model="config.displayDistrictInfo" row>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row dense class="bg-blue">
            <v-col cols="12" md="12">
              <v-subheader class="pl-0"> Display area to enter actual trip time on invoice? </v-subheader>
              <v-radio-group v-model="config.displayActualTripTime" row>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row dense class="bg-blue">
            <v-col cols="12" md="12">
              <v-subheader class="pl-0"> Display area to enter actual driver time on invoice? </v-subheader>
              <v-radio-group v-model="config.displayActualDriverTime" row>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="12">
              <v-subheader class="pl-0"> Invoice rate to be used? </v-subheader>
              <v-radio-group v-model="config.invoiceRate" row>
                <v-radio label="Individual Rates" :value="0"></v-radio>
                <v-radio label="Standard District-Wide Rates" :value="1"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row dense class="bg-blue px-2" v-show="config.invoiceRate">
            <v-col cols="12" md="12">
              <v-subheader class="pl-0"> Select the rate to be used as the default on the Staff Costs tab </v-subheader>
              <v-row>
                <v-col cols="12">
                  <div class="mb-5">
                    <v-form
                      v-if="isAddingDistrictWideRate"
                      ref="districtWRateForm"
                      @submit="addDistrictWideRate"
                      lazy-validation
                    >
                      <v-row>
                        <v-col cols="3">
                          <v-text-field
                            :error-messages="handleErrors($v.districtWideRate.name)"
                            v-model="districtWideRate.name"
                            label="Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :error-messages="handleErrors($v.districtWideRate.rate)"
                            v-model="districtWideRate.rate"
                            label="Rate"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :error-messages="handleErrors($v.districtWideRate.otRate)"
                            v-model="districtWideRate.otRate"
                            label="OT Rate"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :error-messages="handleErrors($v.districtWideRate.otherRate)"
                            v-model="districtWideRate.otherRate"
                            label="Other Rate"
                            type="number"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <div class="d-flex justify-end">
                        <v-btn type="submit" class="mr-2" color="primary">Save</v-btn>
                        <v-btn @click="clearDistrictWideRate">Cancel</v-btn>
                      </div>
                    </v-form>
                    <v-btn v-else @click="isAddingDistrictWideRate = true" color="primary">
                      Add District Wide Rate
                    </v-btn>
                  </div>
                  <div>
                    <v-data-table
                      :headers="districtWideRateHeaders"
                      :items="config.districtWideRates"
                      class="elevation-1"
                    >
                      <template v-slot:item.name="{ item }">
                        <span v-if="config.defaultDistrictWideRate.key !== item.key">{{ item.name }}</span>
                        <span v-else>
                          <v-icon class="">mdi-check</v-icon>
                          <span class="font-weight-bold" style="color: green">{{ item.name }}</span>
                          <span class="font-italic ml-5">(Default)</span>
                        </span>
                      </template>

                      <template v-slot:item.actions="{ item, index }">
                        <v-icon
                          v-if="config.defaultDistrictWideRate.key !== item.key"
                          @click="setDefaultDistrictWideRate(item)"
                          >mdi-check</v-icon
                        >
                        <span v-else class="mr-5">&nbsp;</span>
                        <v-icon class="ml-5" @click="removeDistrictWideRate(item.key, index)">mdi-delete</v-icon>
                      </template>
                    </v-data-table>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="12">
              <v-subheader class="pl-0"> Calculate benefits along with driver rate? </v-subheader>
              <v-radio-group v-model="config.calculateBenefitsWithDriverRate" row>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
              <v-row v-if="config.calculateBenefitsWithDriverRate">
                <v-col cols="3">
                  <v-text-field
                    v-model="config.FICASSRate"
                    :error-messages="handleErrors($v.config.FICASSRate)"
                    type="number"
                    label="FICA (SS)"
                    @input="$v.config.FICASSRate.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="config.FICAMedicareRate"
                    :error-messages="handleErrors($v.config.FICAMedicareRate)"
                    type="number"
                    label="FICA (Medicare)"
                    @input="$v.config.FICAMedicareRate.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="config.RetirementRate"
                    :error-messages="handleErrors($v.config.RetirementRate)"
                    type="number"
                    label="Retirement"
                    @input="$v.config.RetirementRate.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="config.WorkersCompRate"
                    :error-messages="handleErrors($v.config.WorkersCompRate)"
                    type="number"
                    label="Worker's Comp"
                    @input="$v.config.WorkersCompRate.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row dense class="bg-blue">
            <v-col cols="12" md="12">
              <v-subheader class="pl-0"> Invoice Status Bulk Update Access </v-subheader>
              <div class="d-md-flex">
                <v-switch
                  v-model="config.allowInvoiceStatusBatchUpdate.fundingManager"
                  :label="`Allow Funding Manager to Bulk Update Invoices`"
                ></v-switch>
                <v-switch
                  class="ml-8"
                  v-model="config.allowInvoiceStatusBatchUpdate.schoolFinance"
                  :label="`Allow School Finance to Bulk Update Invoices`"
                ></v-switch>
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-subheader class="pl-0"> Default pickup miles application? </v-subheader>
              <v-radio-group v-model="config.pickupMilesApplication" row>
                <v-radio label="Add" value="add" />
                <v-radio label="Subtract" value="subtract" />
                <v-radio label="None" value="none" />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';
import { required, decimal, minValue } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';
import { cloneDeep } from 'lodash';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';

export default {
  mixins: [promptBeforeLeave],
  inject: ['eventHub'],
  components: {},
  data: () => ({
    config: {
      displayActualTripTime: false,
      displayDistrictInfo: false,
      displayActualDriverTime: false,
      allowInvoiceStatusBatchUpdate: {
        fundingManager: false,
        schoolFinance: false,
      },
      districtWideRates: [],
      defaultDistrictWideRate: {},
      pickupMilesApplication: 'none',
    },
    districtWideRate: {
      name: null,
      rate: null,
      otRate: null,
      otherRate: null,
      key: null,
    },
    districtWideRateHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Rate', value: 'rate' },
      { text: 'OT Rate', value: 'otRate' },
      { text: 'Other Rate', value: 'otherRate' },
      { text: 'Action', value: 'actions' },
    ],
    isAddingDistrictWideRate: false,
  }),
  validations() {
    return {
      config: {
        FICASSRate: this.config.calculateBenefitsWithDriverRate
          ? { required, decimal }
          : { skipValidation: (value) => true },
        FICAMedicareRate: this.config.calculateBenefitsWithDriverRate
          ? { required, decimal }
          : { skipValidation: (value) => true },
        RetirementRate: this.config.calculateBenefitsWithDriverRate
          ? { required, decimal }
          : { skipValidation: (value) => true },
        WorkersCompRate: this.config.calculateBenefitsWithDriverRate
          ? { required, decimal }
          : { skipValidation: (value) => true },
      },
      districtWideRate: {
        name: { required },
        rate: { required, decimal, minValue: minValue(0) },
        otRate: { required, decimal, minValue: minValue(0) },
        otherRate: { required, decimal, minValue: minValue(0) },
      },
    };
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async fetchItems() {
      this.invoiceConfig = await this.getConfig('invoice');
      this.config = cloneDeep({ ...this.config, ...this.invoiceConfig });
      this.baseFormValue = cloneDeep(this.config);
    },
    handleErrors,
    async save() {
      try {
        if (!this.config.calculateBenefitsWithDriverRate) {
          this.config.FICASSRate = 0;
          this.config.FICAMedicareRate = 0;
          this.config.RetirementRate = 0;
          this.config.WorkersCompRate = 0;
        } else {
          this.config.FICASSRate = Number(this.config.FICASSRate);
          this.config.FICAMedicareRate = Number(this.config.FICAMedicareRate);
          this.config.RetirementRate = Number(this.config.RetirementRate);
          this.config.WorkersCompRate = Number(this.config.WorkersCompRate);
        }

        if (this.$v.config.$invalid) {
          this.$myalert.error('Please fill in all required fields', true);
          return;
        }

        const r = await this.setConfig({ name: 'invoice', value: this.config });
        if (r && r.done) {
          this.baseFormValue = cloneDeep(this.config);
          this.$myalert.success('Invoice config options saved');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    setHours(value, param) {
      this.config[param] = value;
    },
    addDistrictWideRate(e) {
      e.preventDefault();
      this.$v.districtWideRate.$touch();

      if (this.$v.districtWideRate.$invalid) {
        this.$myalert.error('Please fill in all required fields', true);
        return;
      }

      this.districtWideRate.key = new Date().getTime() + this.districtWideRate.name;

      this.config.districtWideRates.push(this.districtWideRate);

      if (this.config.districtWideRates.length === 1) this.setDefaultDistrictWideRate(this.districtWideRate);
      this.clearDistrictWideRate();
    },

    clearDistrictWideRate() {
      this.districtWideRate = {
        name: null,
        rate: null,
        otRate: null,
        otherRate: null,
        key: null,
      };
      this.$v.districtWideRate.$reset();
      this.isAddingDistrictWideRate = false;
    },

    setDefaultDistrictWideRate(districtWideRate) {
      this.config.defaultDistrictWideRate = { ...districtWideRate };
    },

    removeDistrictWideRate(key, index) {
      if (this.config.defaultDistrictWideRate.key === key) this.config.defaultDistrictWideRate = {};

      this.config.districtWideRates.splice(index, 1);
    },
  },
  created() {
    this.eventHub.$on('saveInvoiceConfig', () => this.save());
  },
  beforeDestroy() {
    this.eventHub.$off('saveInvoiceConfig');
  },
  watch: {
    config: {
      handler(value) {
        this.latestFormValue = cloneDeep(value);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.save-btn {
  float: right;
  margin-bottom: 12px;
}
.bg-blue {
  background-color: aliceblue;
}
.inline {
  display: inline-block;
}
.text-field {
  max-width: 100px;
}
</style>
