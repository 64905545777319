var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-form",
        { attrs: { readonly: !_vm.canEditSettings } },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Invoice")]),
              _c(
                "v-card-text",
                [
                  _c("h3", { staticClass: "mb-3" }, [
                    _vm._v("Vehicle/Driver Assignment Form Sections"),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(
                              " Display district name and address at the top of the printed invoice? "
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.config.displayDistrictInfo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.config,
                                    "displayDistrictInfo",
                                    $$v
                                  )
                                },
                                expression: "config.displayDistrictInfo",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Yes", value: true },
                              }),
                              _c("v-radio", {
                                attrs: { label: "No", value: false },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "bg-blue", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(
                              " Display area to enter actual trip time on invoice? "
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.config.displayActualTripTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.config,
                                    "displayActualTripTime",
                                    $$v
                                  )
                                },
                                expression: "config.displayActualTripTime",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Yes", value: true },
                              }),
                              _c("v-radio", {
                                attrs: { label: "No", value: false },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "bg-blue", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(
                              " Display area to enter actual driver time on invoice? "
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.config.displayActualDriverTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.config,
                                    "displayActualDriverTime",
                                    $$v
                                  )
                                },
                                expression: "config.displayActualDriverTime",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Yes", value: true },
                              }),
                              _c("v-radio", {
                                attrs: { label: "No", value: false },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(" Invoice rate to be used? "),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.config.invoiceRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.config, "invoiceRate", $$v)
                                },
                                expression: "config.invoiceRate",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Individual Rates", value: 0 },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "Standard District-Wide Rates",
                                  value: 1,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.config.invoiceRate,
                          expression: "config.invoiceRate",
                        },
                      ],
                      staticClass: "bg-blue px-2",
                      attrs: { dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(
                              " Select the rate to be used as the default on the Staff Costs tab "
                            ),
                          ]),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "div",
                                  { staticClass: "mb-5" },
                                  [
                                    _vm.isAddingDistrictWideRate
                                      ? _c(
                                          "v-form",
                                          {
                                            ref: "districtWRateForm",
                                            attrs: { "lazy-validation": "" },
                                            on: {
                                              submit: _vm.addDistrictWideRate,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "3" } },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "error-messages":
                                                          _vm.handleErrors(
                                                            _vm.$v
                                                              .districtWideRate
                                                              .name
                                                          ),
                                                        label: "Name",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.districtWideRate
                                                            .name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.districtWideRate,
                                                            "name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "districtWideRate.name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "3" } },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "error-messages":
                                                          _vm.handleErrors(
                                                            _vm.$v
                                                              .districtWideRate
                                                              .rate
                                                          ),
                                                        label: "Rate",
                                                        type: "number",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.districtWideRate
                                                            .rate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.districtWideRate,
                                                            "rate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "districtWideRate.rate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "3" } },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "error-messages":
                                                          _vm.handleErrors(
                                                            _vm.$v
                                                              .districtWideRate
                                                              .otRate
                                                          ),
                                                        label: "OT Rate",
                                                        type: "number",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.districtWideRate
                                                            .otRate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.districtWideRate,
                                                            "otRate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "districtWideRate.otRate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "3" } },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "error-messages":
                                                          _vm.handleErrors(
                                                            _vm.$v
                                                              .districtWideRate
                                                              .otherRate
                                                          ),
                                                        label: "Other Rate",
                                                        type: "number",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.districtWideRate
                                                            .otherRate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.districtWideRate,
                                                            "otherRate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "districtWideRate.otherRate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-end",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      type: "submit",
                                                      color: "primary",
                                                    },
                                                  },
                                                  [_vm._v("Save")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    on: {
                                                      click:
                                                        _vm.clearDistrictWideRate,
                                                    },
                                                  },
                                                  [_vm._v("Cancel")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "v-btn",
                                          {
                                            attrs: { color: "primary" },
                                            on: {
                                              click: function ($event) {
                                                _vm.isAddingDistrictWideRate = true
                                              },
                                            },
                                          },
                                          [_vm._v(" Add District Wide Rate ")]
                                        ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c("v-data-table", {
                                      staticClass: "elevation-1",
                                      attrs: {
                                        headers: _vm.districtWideRateHeaders,
                                        items: _vm.config.districtWideRates,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "item.name",
                                          fn: function ({ item }) {
                                            return [
                                              _vm.config.defaultDistrictWideRate
                                                .key !== item.key
                                                ? _c("span", [
                                                    _vm._v(_vm._s(item.name)),
                                                  ])
                                                : _c(
                                                    "span",
                                                    [
                                                      _c("v-icon", {}, [
                                                        _vm._v("mdi-check"),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight-bold",
                                                          staticStyle: {
                                                            color: "green",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.name)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-italic ml-5",
                                                        },
                                                        [_vm._v("(Default)")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.actions",
                                          fn: function ({ item, index }) {
                                            return [
                                              _vm.config.defaultDistrictWideRate
                                                .key !== item.key
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setDefaultDistrictWideRate(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("mdi-check")]
                                                  )
                                                : _c(
                                                    "span",
                                                    { staticClass: "mr-5" },
                                                    [_vm._v(" ")]
                                                  ),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "ml-5",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeDistrictWideRate(
                                                        item.key,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("mdi-delete")]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(
                              " Calculate benefits along with driver rate? "
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value:
                                  _vm.config.calculateBenefitsWithDriverRate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.config,
                                    "calculateBenefitsWithDriverRate",
                                    $$v
                                  )
                                },
                                expression:
                                  "config.calculateBenefitsWithDriverRate",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Yes", value: true },
                              }),
                              _c("v-radio", {
                                attrs: { label: "No", value: false },
                              }),
                            ],
                            1
                          ),
                          _vm.config.calculateBenefitsWithDriverRate
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "error-messages": _vm.handleErrors(
                                            _vm.$v.config.FICASSRate
                                          ),
                                          type: "number",
                                          label: "FICA (SS)",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.config.FICASSRate.$touch()
                                          },
                                        },
                                        model: {
                                          value: _vm.config.FICASSRate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "FICASSRate",
                                              $$v
                                            )
                                          },
                                          expression: "config.FICASSRate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "error-messages": _vm.handleErrors(
                                            _vm.$v.config.FICAMedicareRate
                                          ),
                                          type: "number",
                                          label: "FICA (Medicare)",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.config.FICAMedicareRate.$touch()
                                          },
                                        },
                                        model: {
                                          value: _vm.config.FICAMedicareRate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "FICAMedicareRate",
                                              $$v
                                            )
                                          },
                                          expression: "config.FICAMedicareRate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "error-messages": _vm.handleErrors(
                                            _vm.$v.config.RetirementRate
                                          ),
                                          type: "number",
                                          label: "Retirement",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.config.RetirementRate.$touch()
                                          },
                                        },
                                        model: {
                                          value: _vm.config.RetirementRate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "RetirementRate",
                                              $$v
                                            )
                                          },
                                          expression: "config.RetirementRate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "error-messages": _vm.handleErrors(
                                            _vm.$v.config.WorkersCompRate
                                          ),
                                          type: "number",
                                          label: "Worker's Comp",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.config.WorkersCompRate.$touch()
                                          },
                                        },
                                        model: {
                                          value: _vm.config.WorkersCompRate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "WorkersCompRate",
                                              $$v
                                            )
                                          },
                                          expression: "config.WorkersCompRate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "bg-blue", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(" Invoice Status Bulk Update Access "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-md-flex" },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: `Allow Funding Manager to Bulk Update Invoices`,
                                },
                                model: {
                                  value:
                                    _vm.config.allowInvoiceStatusBatchUpdate
                                      .fundingManager,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.config.allowInvoiceStatusBatchUpdate,
                                      "fundingManager",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "config.allowInvoiceStatusBatchUpdate.fundingManager",
                                },
                              }),
                              _c("v-switch", {
                                staticClass: "ml-8",
                                attrs: {
                                  label: `Allow School Finance to Bulk Update Invoices`,
                                },
                                model: {
                                  value:
                                    _vm.config.allowInvoiceStatusBatchUpdate
                                      .schoolFinance,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.config.allowInvoiceStatusBatchUpdate,
                                      "schoolFinance",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "config.allowInvoiceStatusBatchUpdate.schoolFinance",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-subheader", { staticClass: "pl-0" }, [
                            _vm._v(" Default pickup miles application? "),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.config.pickupMilesApplication,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.config,
                                    "pickupMilesApplication",
                                    $$v
                                  )
                                },
                                expression: "config.pickupMilesApplication",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Add", value: "add" },
                              }),
                              _c("v-radio", {
                                attrs: { label: "Subtract", value: "subtract" },
                              }),
                              _c("v-radio", {
                                attrs: { label: "None", value: "none" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }